import React from 'react';
import { Box, Typography, Link, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { supportEmail } from '../../common';
import { useLocation } from 'react-router-dom';
import EmailIcon from '../EmailIcon/EmailIcon';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const isDarkMode = theme.palette.mode === 'dark';
  const youTubeChannel = 'https://www.youtube.com/channel/UCxcUSqxqnkIeAY1Bda5ihTw';
  const youTubeLogo = isDarkMode ? '/images/yt_logo_rgb_dark.png' : '/images/yt_logo_rgb_light.png';
  const instagramLink = 'https://instagram.com/love.coupons.app';
  const instagramLogo = '/images/insta.svg';
  const tiktokLink = 'https://tiktok.com/@love.coupons.app';
  const tiktokLogo =  isDarkMode ? '/images/tiktok_dark.svg' : '/images/tiktok_light.svg';

  const getBgColor = () => {
    switch (location.pathname) {
      case '/coupons/about':
      case '/coupons/terms-of-use':
      case '/coupons/privacy-policy':
      case '/coupons/account-deletion':
        return isDarkMode
        ? 'rgba(0, 0, 0, 0.5)'  // black with 50% opacity
        : 'rgba(255, 255, 255, 0.5)';  // light blue with 50% opacity
      default:
        return isDarkMode ? 'rgba(66, 66, 66, 0.5)' : 'rgba(255, 255, 255, 0.5)';  // gray or white with 50% opacity
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        py: 1,
        px: 2,
        backgroundColor: getBgColor(),
        color: 'white',
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2, // Consistent spacing between links
        }}
      >
        <Link href={youTubeChannel} target="_blank" rel="noopener noreferrer">
          <img
            src={youTubeLogo}
            alt="YouTube Channel"
            style={{
              height: '30px',
              display: 'block', // Prevents image misalignment due to inline properties
              background: isDarkMode ? 'black' : 'white',
              padding: '7px',
              borderRadius: '5px',
            }}
          />
        </Link>
        <Link href={instagramLink} target="_blank" rel="noopener noreferrer">
          <img
            src={instagramLogo}
            alt="Instagram"
            style={{
              height: '30px',
              display: 'block',
              background: isDarkMode ? 'black' : 'white',
              padding: '5px',
              borderRadius: '5px',
            }}
          />
        </Link>
        <Link href={tiktokLink} target="_blank" rel="noopener noreferrer">
          <img
            src={tiktokLogo}
            alt="TikTok"
            style={{
              height: '30px',
              display: 'block',
              background: isDarkMode ? 'black' : 'white',
              padding: '3px',
              borderRadius: '5px',
            }}
          />
        </Link>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1, // Adds spacing between icon and text
          color: isDarkMode ? 'white' : 'black',
        }}
      >
        <Link
          href={`mailto:${supportEmail}`}
          sx={{
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            color: 'inherit',
          }}
        >
          <EmailIcon />
          {<Typography variant="body2" sx={{ fontSize: '0.9rem', overflowWrap: 'break-word', marginLeft: 1, textDecoration: 'underline' }}>
            {isMobile ? 'email' : supportEmail}
          </Typography>}
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;